import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BeneficiaryResponse } from "src/app/shared/models/beneficiary";
import { HttpService } from "src/app/shared/services/http.service";
import { Utility } from "src/app/shared/services/utility.service";
import { TRANSACTIONS } from "src/app/shared/statics/samples";
import { GenericDialogComponent } from "../new-beneficiary/new-beneficiary.component";

@Component({
  selector: "vex-beneficiary-details",
  templateUrl: "./beneficiary-details.component.html",
  styleUrls: ["./beneficiary-details.component.scss"],
})
export class BeneficiaryDetailsComponent implements OnInit {
  remitters = TRANSACTIONS;
  title = "Customer Verification";
  // ben: BeneficiaryResponse;
  ben: any;
  check1 = false;
  check2 = false;
  check3 = false;
  check4 = false;
  check5 = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private api: HttpService,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    this.api.getAll("remitters/pendingBeneficiaries/" + id).subscribe((rs: BeneficiaryResponse) => {
      this.ben = rs;
      console.log(rs);
    });
  }
  approve() {
    Utility.getConfirmation(
      "You are about to approve " + this.ben?.beneficiary?.name + " as a valid Beneficiary. Click 'Yes' to continue",
      () => {
        const id = this.route.snapshot.params.id;
        this.api.postSimple("remitters/approveBeneficiary/" + id).subscribe((res) => {
          Utility.showSuccess(`${this.ben.beneficiary.name} approved`);
          this.router.navigate(["/customer/beneficiaries"])
        });
      }
    );
  }

  reject(rem) {
    Utility.getConfirmation(
      "You are about to reject " + this.ben?.beneficiary?.name + " as a valid Beneficiary. Click 'Yes' to continue",
      () => {}
    );
  }
  open(img) {
    this.dialog.open(GenericDialogComponent, {
      data: img,
    });
  }
}
