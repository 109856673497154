<h3>{{title}}</h3>
<div class="container p-gutter table-wrapper">
  <div class="card p-5">
    <table>
      <thead>
        <tr>
          <th>Sl</th>
          <th>Remitter</th>
          <th>Beneficiary</th>
          <th>Current Photo</th>
          <th>New Photo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let rem of bens; let i = index">
          <tr>
            <td>{{i + 1}}</td>
            <td>{{rem?.customer?.fullname}}<div><small>({{rem?.customer?.mobileNumber}})</small></div></td>
            <td>{{rem?.beneficiary?.name}}</td>
            <td><img [src]="rem?.existingPhoto" (click)="open(rem?.existingPhoto)"></td>
            <td><img [src]="rem?.beneficiary?.senderPhoto" (click)="open(rem?.beneficiary?.senderPhoto)"></td>
            <td [routerLink]="['/customer/beneficiary', rem?.beneficiary?.id]" routerLinkActive="router-link-active" >
              <button mat-raised-button>View</button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>