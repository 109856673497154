import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LoginRequest } from "../models/login-request";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  constructor(private http: HttpClient, private storage: StorageService, private router: Router) {}

  login(loginRequest: LoginRequest) {
    let promise = new Promise<boolean>((resolve, reject) => {
      const url = environment.apiRoot + "/auth/authenticate";
      let options = {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      };
      this.http
        .post(url, loginRequest, options)
        .toPromise()
        .then(
          (res) => {
            this.storage.set("auth", res);
            setTimeout(() => {
              resolve(true);
            }, 100);
          },
          (error) => {
            reject(error);
          }
        );
    });
    return promise;
  }

  // login(loginRequest: LoginRequest) {
  //   let promise = new Promise<boolean>((resolve, reject) => {
  //     const url = environment.apiRoot + "/auth/authenticate";
  //     let options = {
  //       headers: new HttpHeaders().set("Content-Type", "application/json"),
  //     };
  //     this.http
  //       .post(url, loginRequest, options)
  //       .toPromise()
  //       .then(
  //         (res) => {
  //           this.storage.set("auth", res);
  //           setTimeout(() => {
  //             resolve(true);
  //           }, 100);
  //         },
  //         (error) => {
  //           reject();
  //         }
  //       );
  //   });
  //   return promise;
  // }

  public get isLoggedIn() {
    const token = this.storage.get("auth.token");
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  requestCode() {
    const url = environment.apiRoot + "/auth/request-code";
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http.post(url, {}, options).toPromise();
  }

  verifyCode(code: string) {
    let promise = new Promise<boolean>((resolve, reject) => {
      const url = environment.apiRoot + "/auth/verify-code";
      let options = {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      };
      this.http
        .post(url, { code: code }, options)
        .toPromise()
        .then(
          (res) => {
            resolve(true);
          },
          (error) => {
            reject();
          }
        );
    });
    return promise;
  }

  logout2() {
    this.storage.clear();
    this.router.navigate(["/login"]);
  }

  logout() {
    let promise = new Promise<boolean>((resolve, reject) => {
      const url = environment.apiRoot + "/auth/logout";
      let options = {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      };
      this.http
        .post(url, {}, options)
        .toPromise()
        .then(
          (res) => {
            this.storage.clear();
            this.router.navigate(["/login"]);
          },
          (error) => {
            reject(error);
          }
        );
    });
    return promise;
  }
}
