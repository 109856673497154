import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { ConfigService } from "../@vex/services/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import { ConfigName } from "../@vex/interfaces/config-name.model";
import { ADMIN_MENU } from "./menu";
import icLayers from "@iconify/icons-ic/twotone-layers";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "vex";
  menu = ADMIN_MENU;
  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    // this.configService.setConfig(ConfigName.ikaros);

    this.configService.updateConfig({
      sidenav: {
        title: "Qpay Admin",
        showCollapsePin: true,
      },
      footer: {
        visible: false,
      },
    });

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap
      .pipe(map((queryParamMap) => queryParamMap.has("rtl") && coerceBooleanProperty(queryParamMap.get("rtl"))))
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? "rtl" : "ltr";
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) => this.configService.setConfig(queryParamMap.get("layout") as ConfigName));

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) => this.styleService.setStyle(queryParamMap.get("style") as Style));

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: "subheading",
        label: "Customer",
        children: [
          {
            type: "dropdown",
            label: "Customer Management",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Customer Verification ",
                route: "customer/apps",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Customers",
                route: "/customer",
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },

          {
            type: "link",
            label: "Beneficiary Verification",
            route: "/customer/beneficiaries",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: "subheading",
        label: "Transaction",
        children: [
          {
            type: "dropdown",
            label: "Reports",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Report",
                route: "/transaction/report",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "BNM Report",
                route: "/reporting/bnm-report",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            type: "dropdown",
            label: "Authorization",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Transaction ",
                route: "/transaction/pending-approval",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "On-Behalf Transaction",
                route: "/transaction/onbehalf",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Online Payment",
                route: "/transaction/online",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            type: "dropdown",
            label: "Customer Support",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Pending Application",
                route: "/customer/applications",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Amendment Requests",
                route: "/transaction/amend",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Cancellation Requests",
                route: "/transaction/cancel",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Refunds",
                route: "/transaction/refund",
                icon: icLayers,
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
        ],
      },
      {
        type: "subheading",
        label: "Reporting",
        children: [
          {
            type: "link",
            label: "Transaction Report",
            route: "/reporting/transaction-report",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: "link",
            label: "Cancellation Report",
            route: "/reporting/cancel-report",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: "link",
            label: "Audit Report",
            route: "/reporting/audit-log",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: "subheading",
        label: "Settings",
        children: [
          {
            type: "link",
            label: "Banks",
            route: "/settings/banks",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: "dropdown",
            label: "General",
            icon: icLayers,
            children: [
              // {
              //   type: "link",
              //   label: "App Settings",
              //   route: "/settings/app-settings",
              //   routerLinkActiveOptions: { exact: true },
              // },
              {
                type: "dropdown",
                label: "App Settings",
                children: [
                  {
                    type: "link",
                    label: "FAQs",
                    route: "/settings/faqs",
                  },
                ],
              },
              {
                type: "link",
                label: "Branches",
                route: "/settings/branches",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Country",
                route: "/settings/country",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Customers",
                route: "/remitter",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Token",
                route: "/settings/token",
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            type: "dropdown",
            label: "User Management",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Users",
                route: "/settings/user",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Customers",
                route: "/remitter",
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },

          {
            type: "link",
            label: "Beneficiary Verification",
            route: "/aml/beneficiary",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: "subheading",
        label: "QuickPay",
        children: [
          {
            type: "dropdown",
            label: "Static",
            icon: icLayers,
            children: [
              {
                type: "link",
                label: "Rate",
                route: "/settings/country",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Country",
                route: "/remitter",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Bank",
                route: "/quickpay/banks",
                routerLinkActiveOptions: { exact: true },
              },
              {
                type: "link",
                label: "Occupations",
                route: "/remitter",
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            type: "link",
            label: "Remitter",
            route: "/aml/beneficiary",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: "link",
            label: "Transaction Status",
            route: "/aml/beneficiary",
            icon: icLayers,
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
    ];
  }
}
