import { Component, Inject, OnInit } from "@angular/core";
import { Utility } from "src/app/shared/services/utility.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpService } from "src/app/shared/services/http.service";
import { BeneficiaryResponse } from "src/app/shared/models/beneficiary";

@Component({
  selector: "vex-new-beneficiary",
  templateUrl: "./new-beneficiary.component.html",
  styleUrls: ["./new-beneficiary.component.scss"],
})
export class NewBeneficiaryComponent implements OnInit {
  remitters: any[] = [];
  bens: BeneficiaryResponse[] = [];
  title = "Beneficiary Verification";
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private api: HttpService
  ) {
    // this.router.events.subscribe((e) => {
    //   if (e instanceof NavigationStart) {
    //     if (e.url.includes("ben")) {
    //       this.title = "Beneficiary Verification";
    //     } else {
    //       this.title = "Remitter Verification";
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    this.api.getAll("remitters/pendingBeneficiaries").subscribe((rs: BeneficiaryResponse[]) => {
      this.bens = rs;
      console.log(rs);
    });

    // this.fs.getMapped("application-data").subscribe((res) => {
    //   this.remitters = res;
    // });
  }

  approve(rem) {
    Utility.getConfirmation(
      "You are about to approve " + rem.fullname + " as a added by valid remitter. Click 'Yes' to continue",
      () => {}
    );
  }

  open(img) {
    this.dialog.open(GenericDialogComponent, {
      data: img,
    });
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  template: `<img [src]="data" />`,
})
export class GenericDialogComponent {
  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
