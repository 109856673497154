export const TRANSACTIONS: TranDetails[] = [
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    status: "New beneficary, need manual verification",
    approved: false,
    rejected: false,
    raml: true,
    baml: true,
  },
];

export interface TranDetails {
  fullname: string;
  passportNumber: string;
  passportExpiryDate: string;
  contactNumber: string;
  status: string;
  approved: boolean;
  rejected: boolean;
  raml: boolean;
  baml: boolean;
}

export const REMITTERS = [
  {
    occupation: "Construction",
    addressPostcode: "46150",
    passportNumber: "AK12345678",
    gender: "Male",
    addressCountry: "Malaysia",
    sourceOfIncome: "Salary",
    confirm: true,
    streetAddress: "Mentari Court A-12-12",
    companyAddress2: "",
    applicationNumber: "4652754867",
    mobileNumber: "0123456789",
    companyCity: "Bandar Sunway",
    edit: false,
    companyPostcode: "46150",
    dateOfBirth: "1981-06-18",
    nationality: "Bangladesh",
    fullname: "Abdul Kader",
    visaExpiryDate: "2021-12-12",
    streetAddress2: "Jalan PJS 9/12",
    addressCity: "Jalan PJS 9/11",
    sl: 1,
    passportExpiryDate: "2023-12-12",
    companyAddress: "Jalan PJS 1/12",
    companyName: "Sunway Construction",
    id: "2kXiPAG0SceWVGJKCLWG",
    rbaScore: "3 (low)",
    sanction: false,
    pep:false
  },
  {
    companyCity: "",
    sourceOfIncome: "Salary",
    nationality: "Bangladesh",
    companyAddress2: "",
    dateOfBirth: "2021-03-13",
    applicationNumber: "4652754867",
    passportNumber: "AH3424233",
    mobileNumber: "0123456789",
    passportExpiryDate: "2021-03-12",
    companyPostcode: "",
    visaExpiryDate: "2021-03-20",
    gender: "Male",
    fullname: "Abdul Hashem",
    streetAddress2: "Jalan PJS 9/7",
    occupation: "",
    streetAddress: "Mentari Court A-12-12",
    companyAddress: "",
    companyName: "",
    addressCountry: "Malaysia",
    addressCity: "Bandar Sunway",
    addressPostcode: "46150",
    id: "mehaQQy7vYnOwgOwgg9m",
    rbaScore: "8 (high)",
    sanction: false,
    pep:true
  },
];

export const AMENDMENTS = [
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abdul Hamid",
    tid: 1231546151,
    status: "",
    data: [
      {
        key: "Contact Number",
        oldValue: "0123456789",
        newValue: "0144056789",
      },
    ],
  },
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abu Saleh",
    tid: 1231546197,
    status: "",
    data: [
      {
        key: "Bank Branch",
        oldValue: "Ghulshan Branch",
        newValue: "Banani Branch",
      },
    ],
  },
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abu Hashem",
    tid: 1231546222,
    status: "",
    data: [
      {
        key: "Contact Number",
        oldValue: "0123456789",
        newValue: "0144056789",
      },
      {
        key: "Bank",
        oldValue: "Brack Bank",
        newValue: "National Bank Limited",
      },
      {
        key: "Bank Branch",
        oldValue: "Ghulshan Branch",
        newValue: "Banani Branch",
      },
      {
        key: "Account Number",
        oldValue: "123456789",
        newValue: "98654321",
      },
    ],
  },
];
export const CANCELLATIONS = [
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abdul Hamid",
    tid: 1231546151,
    payoutStatus: "",
    status: "",
    pm: "Cash",
    behalf: false,
    amount: 500
  },
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abu Saleh",
    tid: 1231546197,
    payoutStatus: "",
    status: "",
    pm: "Online",
    behalf: false,
    amount: 1500
  },

  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abu Saleh",
    tid: 1236666197,
    status: "",
    payoutStatus: "",
    pm: "Online",
    behalf: true,
    amount: 1000
  },
  {
    fullname: "Abdul Kader",
    passportNumber: "AK32424234",
    passportExpiryDate: "12-12-2023",
    contactNumber: "0123456789",
    name: "Abu Hashem",
    tid: 1231546222,
    status: "",
    payoutStatus: "Paid",
    pm: "Online",
    behalf: false,
    amount: 300
  },
];
export const REFUNDS = [];
