<h3>{{title}}</h3>
<div class="container p-gutter table-wrapper">
  <div class="card p-5">
    <table>
      <thead>
        <tr>
          <th>Sl</th>
          <th>Name</th>
          <th>IC/Passport</th>
          <th>Phone</th>
          <th>Employer</th>
          <th>Sanction</th>
          <th>PEP</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let rem of remitters; let i = index">
          <tr>
            <td>{{i + 1}}</td>
            <td>{{rem.fullname}}</td>
            <td>{{rem.passportNumber}}<br><small>Exp: {{rem.passportExpiryDate}}</small></td>
            <td>{{rem.contactNumber ? rem.contactNumber : '01156456146'}}</td>
            <td>{{rem.companyName ? rem.companyName: 'Venture Con Com'}}</td>
            <td>
              <mat-icon>done</mat-icon>
              No
            </td>
            <td>
              <mat-icon>done</mat-icon>
              No
            </td>
            <td>Requires approval</td>
            <td (click)="approve(rem)">
              <mat-icon>how_to_reg</mat-icon>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>