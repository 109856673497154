import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { StorageService } from "./storage.service";
import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseUrl = environment.apiRoot + "/";
  // authUrl = environment.host + "/api";
  public loading = false;
  public loadingMessage = "Loading...";
  constructor(private http: HttpClient, private storage: StorageService, private authService: AuthService) {
    this.initAfterLogin();
  }

  initAfterLogin() {}

  //GET
  // authGetAll(controller) {
  //   this.loading = true;
  //   return this.http.get(this.authUrl + controller);
  // }
  public getAll(controller) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller);
  }
  public read(controller, id) {
    this.loading = true;
    return this.http.get(this.baseUrl + controller + "/" + id);
  }
  //CREATE
  create(controller, model) {
    return this.post(controller, model);
  }

  //Post
  postSimple(controller) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, {});
  }
  //Post
  post(controller, model) {
    this.loading = true;
    return this.http.post(this.baseUrl + controller, model);
  }

  //Post
  // print(model) {
  //   this.loading = true;
  //   return this.http.post(environment.printServer, model);
  // }

  // authPost(controller, model) {
  //   this.loading = true;
  //   return this.http.post(this.authUrl + controller, model);
  // }

  public getAllAsync(controller): Observable<any> {
    this.loading = true;
    return this.http.get<any>(this.baseUrl + controller).pipe(tap((_) => console.log("Loaded")));
  }

  //list
  public list(listName) {
    this.loading = true;
    return this.http.get(this.baseUrl + "list/" + listName);
  }

  //READ
  public get(controller, model?) {
    this.loading = true;
    if (model) {
      return this.http.get(this.baseUrl + controller + "/" + model.id);
    }
    return this.http.get(this.baseUrl + controller);
  }

  //Update
  public update(controller, model) {
    this.loading = true;
    return this.http.put(this.baseUrl + controller + "/" + model.id, model);
  }

  //Delete
  public delete(controller, model) {
    this.loading = true;
    return this.http.delete(this.baseUrl + controller + "/" + model.id);
  }

  public downloadPDF(controller, model): any {
    var mediaType = "application/pdf";
    this.http.get(this.baseUrl + controller + model, { responseType: "blob" }).subscribe(
      (response) => {
        var blob = new Blob([response], { type: mediaType });
        console.log(blob);
        return blob;
      },
      (e) => {
        throwError(e);
      }
    );
  }

  getPDF(controller, model): Promise<any> {
    // // const imageBlob = await this.http
    // //   .get(environment.apiRoot + "/file/stream/" + src, { responseType: "blob" })
    // //   .toPromise();

    // const pdfBlob = await this.http.get(this.baseUrl + controller + model).toPromise();

    // //const reader = new FileReader();
    // return new Promise((resolve) => {
    //   resolve(pdfBlob);
    // });
    console.log("get pdf");
    // let promise = new Promise((resolve, reject) => {
    // this.http.get(this.baseUrl + controller + model, {responseType  : 'blob' }).toPromise();
    //     .then(
    //       res => { // Success
    //         //console.log(res.json());
    //         resolve();
    //       }
    //     );
    // });
    // return promise;

    return this.http.get(this.baseUrl + controller + model, { responseType: "application/pdf" as "blob" }).toPromise();
  }

  // getPDF(controller, model){
  //   const url = this.baseUrl + controller + model

  //   const httpOptions = {
  //     'responseType'  : 'arraybuffer' as 'json'
  //      //'responseType'  : 'blob' as 'json'        //This also worked
  //   };

  //   return this.http.get<any>(url, httpOptions);

  //   }
}
