import Swal from "sweetalert2";
export class Utility {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public static getConfirmation(message, success, fail?, title?: string, yesButtonCaption?: string) {
    Swal.fire({
      title: title ? title : null,
      text: message ? message : "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: yesButtonCaption ? yesButtonCaption : "Yes!",
    }).then((result) => {
      if (result.value) {
        success();
      } else {
        fail();
      }
    });
  }
  public static showSuccess(message: string, title?: string) {
    Swal.fire({
      icon: "success",
      title: title ? title : "",
      text: message,
    });
  }
  public static showError(error: any, title?: string) {
    console.log(error);

    var errorMessage = "";
    if (typeof error === "object") {
      if (error.error) {
        if (typeof error.error === "object") {
          const err = error.error;
          if (err.errors) {
            console.log(err.errors);
            err.errors.forEach((e) => {
              console.log(e);
              errorMessage += e;
            });
          } else if (err.message) {
            errorMessage = err.message;
          } else if (err.error_description) {
            errorMessage = err.error_description;
          } else if (err.errorMessage) {
            errorMessage = err.errorMessage;
          }
        } else {
          const err = JSON.parse(error.error);
          if (err.errors) {
            console.log(err.errors);
            err.errors.forEach((e) => {
              console.log(e);
              errorMessage += e;
            });
          } else if (err.message) {
            errorMessage = err.message;
          } else if (err.error_description) {
            errorMessage = err.error_description;
          } else if (err.errorMessage) {
            errorMessage = err.errorMessage;
          }
        }
      } else {
        errorMessage = error;
      }
    }
    Swal.fire({
      icon: "error",
      title: title ? title : "",
      text: errorMessage,
    });
  }
  public static showcError(error: any, title?: string) {
    var errorMessage = "";
    if (typeof error === "object") {
      if (error.error) {
        const err = JSON.parse(error.error);
        console.log(err);
        if (err.errors) {
          console.log(err.errors);
          err.errors.forEach((e) => {
            console.log(e);
            errorMessage += e;
          });
        } else if (err.message) {
          errorMessage = err.message;
        } else if (err.errorMessage) {
          errorMessage = err.errorMessage;
        }
      } else {
        errorMessage = error;
      }
    }
    Swal.fire({
      icon: "error",
      title: title ? title : "",
      text: errorMessage,
    });
  }
  public static showError2(message: string, title?: string) {
    Swal.fire({
      icon: "error",
      title: title ? title : "",
      text: message,
    });
  }

  public static getRandomNumber(length: number) {
    return new Date().getTime().toString().slice(-length);
  }
}
