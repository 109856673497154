import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";

import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from "../services/utility.service";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  count = 0;
  constructor(private storage: StorageService, private auth: AuthService, private spinner: NgxSpinnerService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    // console.log("show spinner");

    this.count++;

    const token = this.storage.get("auth.token");
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    // return next.handle(request);
    return next.handle(request).pipe(
      tap(
        (event) => {
          // console.log(event);
        },

        (error) => {
          if (error?.status === 401) {
            console.log(error?.status);
            this.auth.logout();
          } else {
            console.log(error);

            Utility.showError(error);
          }
        }
      ),
      finalize(() => {
        this.count--;

        if (this.count == 0) {
          this.spinner.hide();
          // console.log("hide spinner");
        }
      })
    );
  }
}
