import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { GenericDialogComponent } from "./modules/customer/new-beneficiary/new-beneficiary.component";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import { AuthService } from "./shared/services/auth.service";
import { MatNativeDateModule } from "@angular/material/core";

@NgModule({
  entryComponents: [GenericDialogComponent],
  declarations: [
    AppComponent,
    // AmlComponent,
    // NewBeneficiaryComponent,
    // TransactionApprovalComponent,
    // RefundsComponent,
    // RequestsComponent,
    // CancelsComponent,
    // TransactionDetailComponent,
    // BeneficiaryDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    // Vex
    VexModule,
    CustomLayoutModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
