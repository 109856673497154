<h3>{{title}}</h3>
<div class="p-gutter table-wrapper">

  <h4>Beneficiary details</h4>
  <section>
    <div class="grid grid-cols-1 gap-1">
      <div class="card p-4">
        <h5>Personal Details</h5>
        <table>
          <tr>
            <td>Fullname</td>
            <td>: {{ben?.beneficiary?.name}}</td>
            <td>Nationality</td>
            <td>: {{ben?.beneficiary?.country?.nationality}}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>: 
              <div>{{ben?.beneficiary?.streetOrVillage}}</div>
              <div>{{ben?.beneficiary?.thanaOrUpazilla}}</div>
              <div>{{ben?.beneficiary?.cityOrDistrict}}</div>
            </td>
            <td>Phone Number</td>
            <td>: {{ben?.beneficiary?.contactNumber}}</td>
          </tr>
        </table>
      </div>
      <div class="card p-4">
        <h5>CDD - Sanction Database</h5>
        <div>{{ben?.sancationData?.length}} closest match</div>
        <table>
          <thead>
            <tr>
              <th>Fullname</th>
              <th>Date of Birth</th>
              <th>Nationality</th>
              <th>Passport/IC</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of ben?.sancationData">
              <td>{{item.individual.name}}</td>
              <td>
                <ng-container *ngFor="let d of item.individual.individuaL_DATE_OF_BIRTH; let di = index">
                  <div>{{d?.date}}</div>
                </ng-container>
              </td>
              <td>
                {{item?.individual?.nationality?.value.join(',')}}
              </td>
              <td>
                <div *ngFor="let doc of item?.individual?.individuaL_DOCUMENT">
                  {{doc?.typE_OF_DOCUMENT}},
                  {{doc?.number}},
                  {{doc?.issuinG_COUNTRY}}
                </div>
              </td>
              <td>
                <div *ngFor="let add of item?.individual?.individuaL_ADDRESS">{{add | json}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      
        <h5 class="mt-4">CDD - PEP Database</h5>
        <div>{{ben?.pepData?.length}} closest match</div>
        <table>
          <thead>
            <tr>
              <th>Fullname</th>
              <th>Date of Birth</th>
              <th>Nationality</th>
              <th>Passport/IC</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of ben?.pepData">
              <td>{{item.individual.name}}</td>
              <td>{{item.individual.dateOfBirth | date}} </td>
              <td> {{item?.individual?.nationality}} </td>
              <td>
                {{item?.individual?.identityType}} {{item?.individual?.identityNumber}}
              </td>
              <td>
                {{item?.individual?.address}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <!-- <h4>RBA</h4>
  <section>
    <table>
      <tr>
        <th>Assessment</th>
        <th>Value</th>
        <th>Score</th>
      </tr>
      <tr>
        <td>1. Type of Customer</td>
        <td>Individual</td>
        <td>1</td>
      </tr>
      <tr>
        <td>2. Nationality</td>
        <td>Bangladesh</td>
        <td>1</td>
      </tr>
      <tr>
        <td>3. Remittance Payout Country</td>
        <td>Bangladesh</td>
        <td>1</td>
      </tr>
      <tr>
        <td>4. Monthly Remitted Value</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>5. Monthly Remitted Volume</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>6. Payment Mode of Transaction</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>7. Payout Mode of Transaction</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>8. Number of Beneficiaries</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>9. Others (Assessed by official during CDD)</td>
        <td></td>
        <td>3</td>
      </tr>
      <tr>
        <th class="text-right" colspan="2">TOTAL SCORE</th>
        <th>6 - Low</th>
      </tr>
    </table>

  </section>
 -->


  <h4>Decision</h4>
  <section>
    <div class="grid grid-cols-2 gap-2">
      <div class="card p-4">
        <!-- <div>
          <mat-checkbox [(ngModel)]="check1">CDD - Sanction Database Check Passed?</mat-checkbox>
        </div>
        <div>
          <mat-checkbox [(ngModel)]="check2">CDD - PEP Database Check Passed?</mat-checkbox>
        </div> -->
        <div>
          <mat-checkbox [(ngModel)]="check3">Beneficiary Sanction Database Check Passed?</mat-checkbox>
        </div>
        <div>
          <mat-checkbox [(ngModel)]="check4">Beneficiary PEP Database Check Passed?</mat-checkbox>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Your Remarks</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
        <!-- <div>
          <mat-checkbox [(ngModel)]="check5">RBA Scoring passed?</mat-checkbox>
        </div> -->
      </div>

      <div class="card p-4">
        <div class="grid grid-cols-2">
          <div style="display: grid; align-items: center; justify-items: center;">
            <br>
            Customer Existing Photo
            <img [src]="ben?.existingPhoto" (click)="open(ben?.existingPhoto)">
          </div>
          <div style="display: grid; align-items: center; justify-items: center;">
            <br>
            Customer Photo During Transaction
            <img [src]="ben?.beneficiary?.senderPhoto" (click)="open(ben?.beneficiary?.senderPhoto)">
          </div>
        </div>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Add RBA Score <small>(Based on CDD)</small></mat-label>
          <input matInput>
        </mat-form-field> -->
      </div>
    </div>

  </section>

  <div class="text-center">
    <button mat-raised-button class="px-5 mr-5" (click)="approve()" [disabled]="!(check3 && check4)" color="primary">Approve</button>
    <button mat-raised-button class="px-5" color="accent">Reject</button>
  </div>
</div>